<template>
  <div class="msgList-status">
    <section v-if="audit.comment">
      <section v-if="audit.expires_at !== null">
        <section v-if="audit.state === 1" class="gray">
          <span class="amount-show">{{ $t('message.audit.top.amount', audit) }}</span>
          <span>{{ $t('message.audit.top.accept') }}</span>
        </section>
        <section v-else class="gray">
          <span class="amount-show">{{ $t('message.audit.top.amount', audit) }}</span>
          <span>{{ $t('message.audit.top.reject') }}</span>
        </section>
      </section>
      <section
        v-if="audit.expires_at === null"
        class="green"
        @click="showOperations(audit)"
      >
        <span class="audit-show">{{ $t('message.audit.top.amount', audit) }}</span>
        <span class="audit-operation">{{ $t('message.audit.review') }}</span>
      </section>
    </section>
    <section v-if="!audit.comment" class="red">
      {{ $t('article.deleted') }}
    </section>
  </div>
</template>

<script>
/**
 * 提取资讯评论置顶申请的状态控制组件
 */

  import AuditStatusBase from './AuditStatusBase.vue'

  export default {
    name: 'NewsCommentAuditStatus',
    extends: AuditStatusBase,
    methods: {
      accept () {
        const {
          raw: commentId = 0,
          target: newsId = 0,
          id: pinnedId = 0,
        } = this.audit
        this.$http
          .patch(
            `/news/${newsId}/comments/${commentId}/currency-pinneds/${pinnedId}`,
            { validateStatus: s => s === 201 },
          )
          .then(({ data }) => {
            this.audit.state = 1
            this.audit.expires_at = 1
            this.$Message.success(data)
          })
      },
      reject () {
        const {
          id: pinnedId = 0,
          raw: commentId = 0,
          target: newsId = 0,
        } = this.audit
        this.$http
          .patch(
            `/news/${newsId}/comments/${commentId}/currency-pinneds/${pinnedId}/reject`,
            { validateStatus: s => s === 204 },
          )
          .then(() => {
            this.audit.state = 2
            this.audit.expires_at = 1
          })
      },
    },
  }
</script>

<style lang="less">
@import url("../style.less");

.gray {
  span {
    margin-right: 30px;
  }
}
</style>
