<template>
  <div class="msgList-status">
    <section v-if="audit.expires_at">
      <section v-if="audit.status === 1" class="gray">
        <span class="amount-show">{{ $t('message.audit.top.amount', audit) }}</span>
        <span>{{ $t('message.audit.top.accept') }}</span>
      </section>
      <section v-else class="gray">
        <span class="amount-show">{{ $t('message.audit.top.amount', audit) }}</span>
        <span>{{ $t('message.audit.top.reject') }}</span>
      </section>
    </section>
    <section
      v-else
      class="green"
      @click="showOperations(audit)"
    >
      <span class="audit-show">{{ $t('message.audit.top.amount', audit) }}</span>
      <span class="audit-operation">{{ $t('message.audit.review') }}</span>
    </section>
  </div>
</template>

<script>
/**
 * 提取资讯评论置顶申请的状态控制组件
 */

  import AuditStatusBase from './AuditStatusBase.vue'

  export default {
    name: 'NewsCommentAuditStatus',
    extends: AuditStatusBase,
    methods: {
      accept () {
        const { target: postId = 0 } = this.audit
        this.$http
          .patch(`/plus-group/currency-pinned/posts/${postId}/accept`, {
            validateStatus: s => s === 201,
          })
          .then(({ data }) => {
            this.audit.expires_at = 1
            this.audit.status = 1
            this.$Message.success(data)
          })
      },
      reject () {
        const { target: postId = 0 } = this.audit
        this.$http
          .patch(`/plus-group/currency-pinned/posts/${postId}/reject`, {
            validateStatus: s => s === 201,
          })
          .then(({ data }) => {
            this.audit.expires_at = 1
            this.audit.status = 2
            this.$Message.success(data)
          })
      },
    },
  }
</script>

<style lang="less" scoped>
@import url("../style.less");
</style>
