<template>
  <div class="msgList">
    <CommonHeader :pinned="true">{{ '@我的' }}</CommonHeader>

    <div class="msgList-container">
      <JoLoadMore
        ref="loadmore"
        class="msgList-loadmore"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <div
          v-for="noti in findmes"
          :key="noti.id"
          class="msgList-item"
        >
          <MessageAtsItem :comment="noti.data">
            {{ noti.created_at | time2tips }}
          </MessageAtsItem>
        </div>
      </JoLoadMore>
    </div>
  </div>
</template>

<script>
  /**
   * 消息-评论列表
   */
  import * as api from '@/api/message.js'
  import MessageAtsItem from './MessageAtsItem.vue'

  export default {
    name: 'MessageAts',
    components: {
      MessageAtsItem,
    },
    data: () => ({
      findmes: [],
    }),
    mounted () {
      this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      onRefresh () {
        // const type = 'comment'
        const type = 'at'
        api.getNotification({ type, page: this.page })
          .then(({ data }) => {
            const noMore = data.meta.last_page <= data.meta.current_page
            this.findmes = data.data
            // console.log(this.findmes)
            api.resetNotificationCount(type)
            this.$refs.loadmore.afterRefresh(noMore)
            if (!noMore) this.page++
          })
      },
      onLoadMore () {
        // const type = 'comment'
        const type = 'at'
        api.getNotification({ type, page: this.page })
          .then(({ data }) => {
            const noMore = data.meta.last_page <= data.meta.current_page
            this.findmes.push(...data.data)
            this.$refs.loadmore.afterLoadMore(noMore)
            if (!noMore) this.page++
          })
      },
    },
  }
</script>

<style lang="less">
@import url("./style.less");
</style>
