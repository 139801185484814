<template>
  <div class="p-message-audits">
    <CommonHeader :style="{overflow: 'visible'}">
      <DiySelect
        v-model="currentType"
        :options="options"
        style="margin-top: -1px"
      />
    </CommonHeader>

    <div class="container">
      <RouterView />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import i18n from '@/i18n'

  const options = [
    { value: 'feedcomments', label: i18n.t('message.audit.types.feed_comment_top'), storeKey: 'feedCommentCount', unreadKey: 'feed-comment-pinned' },
    { value: 'newscomments', label: i18n.t('message.audit.types.news_comment_top'), storeKey: 'newsCommentCount', unreadKey: 'news-comment-pinned' },
    { value: 'groupcomments', label: i18n.t('message.audit.types.post_comment_top'), storeKey: 'groupPostCommentCount', unreadKey: 'post-comment-pinned' },
    { value: 'groupposts', label: i18n.t('message.audit.types.post_top'), storeKey: 'groupPostCount', unreadKey: 'post-pinned' },
    { value: 'groupjoins', label: i18n.t('message.audit.types.group_join'), storeKey: 'groupJoinCount', unreadKey: 'group-join-pinned' },
  ]

  export default {
    name: 'MessageAudits',

    data () {
      return {
        refreshData: [],
      }
    },
    computed: {
      currentType: {
        get () {
          const match = this.$route.path.match(/\/audits\/(\w+)$/)
          return match && match[1]
        },
        set (val) {
          this.$router.replace(`/message/audits/${val}`)
          this.clearUnread(val)
        },
      },
      options () {
        return options.map(item => {
          item.unread = _.filter(this.$store.state.message.audits[item.storeKey], item => item.status === 0).length
          return item
        })
      },
    },
    created () {
      this.getDefaultView()
    },
    methods: {
      getDefaultView () {
        for (const item of this.options) {
          if (item.unread) return (this.currentType = item.value)
        }
        if (!this.currentType) this.currentType = 'feedcomments'
      },
      clearUnread (currentType) {
        const type = (options.find((value) => value === currentType) || {}).unreadKey
        type && this.$http.patch('/user/counts', { type })
      },
    },
  }
</script>

<style lang="less" scoped>
@import url("./style.less");

.p-message-audits {
  .c-common-header {
    position: fixed;
  }

  .diy-select {
    width: 100%;
  }

  .container {
    padding-top: 90px;
  }
}
</style>
