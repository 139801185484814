<template>
  <div class="c-message-comments-item" @click="toNews">
    <Avatar :user="comment.sender" />

    <main>
      <div class="sender-info">
        <section class="user-info">
          <RouterLink class="username" :to="`/users/${comment.sender.id}`">
            {{ comment.sender.name }}
          </RouterLink>
          <span> @了我</span>
          <p class="time"><slot /></p>
        </section>

      </div>
      <div>
        <Component
          :is="componentMap[type]"
          :id="comment.resource.id"
        />
      </div>
    </main>

  
  </div>
</template>

<script>
  import ReferenceFeed from '@/components/reference/ReferenceFeed.vue'
  import ReferenceNews from '@/components/reference/ReferenceNews.vue'
  import ReferencePost from '@/components/reference/ReferencePost.vue'
  import ReferenceComment from './children/at/MessageCommentItemBase.vue'

  const componentMap = {
    'feeds': ReferenceFeed,
    'news': ReferenceNews,
    'group-posts': ReferencePost,
    'comments': ReferenceComment,
  }

  export default {
    name: 'MessageCommentsItem',
    components: {
      ReferenceFeed,
      ReferenceNews,
      ReferencePost,
      ReferenceComment,
    },
    props: {
      comment: { type: Object, required: true },
    },
    data () {
      return {
        componentMap,
      }
    },
    computed: {
      type () {
        return this.comment.resource.type
      },
    },
    methods: {
      toNews () {
        const id = this.comment.commentable_id
        if (id) {
          this.$router.push({ path: `/news/${id}` })
        }
        return
      },
    },
  }
</script>

<style lang="less" scoped>
.c-message-comments-item {
  display: flex;

  .m-avatar-box {
    margin-right: 15px;
  }

  > main {
    flex: auto;
  }

  .sender-info {
    flex: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 15px;

    .user-info {
      margin-top: 10px;
      font-size: 28px;
      color: #ccc;
      .username{
        color: #1A1A1A;
        margin-right: 8px;
      }
    }

    .reply {
      flex: none;
      font-size: 26px;
      color: #999;
      padding: 6px 15px;
    }

    .time {
      font-size: 26px;
      color: #ccc;
    }
  }
  .comment-item-bottom{
    .content{
      span{
        color: #59b6d7;
        margin-right: 10px;
      }
    }
  }
}
</style>
