<template>
  <div :class="`${prefixCls}`">
    <div :class="`${prefixCls}-container`">
      <JoLoadMore
        ref="loadmore"
        :class="`${prefixCls}-loadmore`"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <div
          v-for="audit in list"
          :key="`news-comment-${audit.id}`"
          :class="`${prefixCls}-item`"
        >
          <div :class="`${prefixCls}-item-top`">
            <Avatar :user="audit.user" />
            <section class="userInfo">
              <RouterLink :class="`${prefixCls}-item-top-link`" :to="`/users/${audit.user_id}`">
                {{ audit.user.name }}
              </RouterLink>
              <p>{{ audit.created_at | time2tips }}</p>
            </section>
            <AuditStatusNewsComment :audit="audit" />
          </div>
          <!--<div :class="`${prefixCls}-item-bottom`" v-if="audit.news">-->
          <!--&lt;!&ndash; <div class="content" @click="goToDetail(audit.news.id)" v-if="audit.comment"> &ndash;&gt;-->
          <!--<div class="content" v-if="audit.comment">-->
          <!--对你的文章进行了“<span>{{ audit.comment.body }}</span>”评论并申请置顶,请及时审核-->
          <!--</div>-->
          <!--&lt;!&ndash; <div class="content" @click="goToDetail(audit.news.id)" v-else> &ndash;&gt;-->
          <!--<div class="content" v-else>-->
          <!--对你的文章进行了评论并申请置顶,请及时审核-->
          <!--</div>-->
          <!--</div>-->
          <!--<div :class="`${prefixCls}-item-bottom`" v-else>-->
          <!--<div class="content red" @click="goToDetail(audit.news.id)">-->
          <!--该文章已被删除-->
          <!--</div>-->
          <!--</div>-->
          <AuditContent :audit="getAuditContent(audit)" />
        </div>
      </JoLoadMore>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { mapState } from 'vuex'
  import { limit } from '@/api'
  import { getNewsCommentPinneds } from '@/api/news.js'
  import * as userApi from '@/api/user'
  import AuditStatusNewsComment from '../../components/AuditStatusNewsComment.vue'
  import AuditContent from '../../components/AuditContent.vue'

  const prefixCls = 'msgList'

  export default {
    name: 'MessageAuditNewsComment',
    components: {
      AuditContent,
      AuditStatusNewsComment,
    },
    data: () => ({
      prefixCls,
      list: [],
    }),
    computed: {
      ...mapState({
        audits: state => state.message.audits.newsCommentPinned,
      }),
    },
    methods: {
      goToDetail (id) {
        this.$router.push(`/news/${id}`)
      },
      async getUser () {
        const id = _.union(Object.values(this.audits).map(item => item.user_id)).join(',')
        if (!id) return this.audits
        return userApi.getUserList({ id })
          .then(({ data }) => {
            const users = new Map()
            data.forEach(item => users.set(item.id, item))
            return this.audits.map(item => {
              item.user = users.get(item.user_id)
              return item
            })
          })
      },
      onRefresh () {
        this.$http.get('/news/comments/pinneds', { validateStatus: s => s === 200 })
          .then(async ({ data }) => {
            if (data.length > 0) {
              this.$store.commit('message/SAVE_AUDIT', { type: 'newsCommentPinned', list: data })
            }
            this.list = await this.getUser()
            this.$refs.loadmore.afterRefresh(data.length < limit)
          })
      },
      onLoadMore () {
        const { id = 0 } = this.audits.slice(-1)[0] || {}
        if (id === 0) {
          this.$refs.loadmore.afterLoadMore(true)
          return false
        }
        getNewsCommentPinneds(id).then(({ data }) => {
          this.$refs.loadmore.afterLoadMore(data.length < limit)
          if (data.length > 0) {
            this.$store.commit('message/SAVE_AUDIT', { type: 'newsCommentPinned', list: data, append: true })
          }
        })
      },
      getAuditContent (audit) {
        const { news = {}, comment = {} } = audit || {}
        return {
          image: this.getFirstImage(news),
          commentBody: this.getCommentBody(comment),
          content: this.getFeedContent(news),
          commentableDel: audit.news === null,
          commentDel: audit.comment === null,
          type: 'news',
          contentId: audit.news ? news.id : 0,
        }
      },
      // 获取评论内容
      getCommentBody (comment) {
        const { body } = comment || {}
        return body
      },
      // 获取动态内容
      getFeedContent (news) {
        const { title } = news || {}
        return title
      },
      // 获取动态第一个图片
      getFirstImage (news) {
        const { image } = news || {}
        if (image !== null && typeof image != 'undefined') {
          return {
            file: image.id,
            size: image.size,
          }
        }
        return false
      },
    },
  }
</script>

<style lang="less" src="../../style.less">
</style>
