<template>
  <div class="c-reference-feed">
    <h5 v-if="noContent">{{ $t('message.not_exist') }}</h5>
    <RouterLink v-else :to="toPath(resource.resourceable)">
      <div class="content">{{ resource.body }}</div>
    </RouterLink>
  </div>
</template>

<script>
  import * as api from '@/api/message.js'

  export default {
    name: 'MessageCommentItemBase',
    props: {
      id: { type: Number, required: true },
    },
    data () {
      return {
        resource: {},
        noContent: false,
      }
    },
    computed: {
      user () {
        const { user } = this.comment
        return user || {}
      },
    },
    methods: {
      async fetchComment () {
        const { data: list, status } = await api.getComment(this.id, { allow404: true })
        if (status === 404) {
          this.noContent = true
        } else {
          this.resource = list[0]
        }
      },
      toPath (resourceable) {
        if (!resourceable) {
          return ''
        }

//         questions
// question-answers
// group-posts
        switch (resourceable.type) {
          case 'feeds':
            return `/feeds/${resourceable.id}`
          case 'questions':
            return `/questions/${resourceable.id}`
          case 'news':
            return `/news/${resourceable.id}`

          default:
            return ''
        }
      },
    },
    created () {
      this.fetchComment()
    }
  }
</script>
