<template>
  <div class="msgList-status">
    <section v-if="audit.comment">
      <section v-if="audit.expires_at">
        <section v-if="~~audit.status === 1" class="gray">
          <span class="amount-show">{{ $t('message.audit.top.amount', audit) }}</span>
          <span>{{ $t('message.audit.top.accept') }}</span>
        </section>
        <section v-else class="gray">
          <span class="amount-show">{{ $t('message.audit.top.amount', audit) }}</span>
          <span>{{ $t('message.audit.top.reject') }}</span>
        </section>
      </section>
      <section
        v-else
        class="green"
        @click="showOperations(audit)"
      >
        <span class="audit-show">{{ $t('message.audit.top.amount', audit) }}</span>
        <span class="audit-operation">{{ $t('message.audit.review') }}</span>
      </section>
    </section>
    <section v-if="!audit.comment " class="red">
      {{ $t('article.deleted') }}
    </section>
  </div>
</template>

<script>
/**
 * 提取动态评论置顶申请的状态控制组件
 */
  import AuditStatusBase from './AuditStatusBase.vue'

  export default {
    name: 'GroupPostCommentAuditStatus',
    extends: AuditStatusBase,
    methods: {
      accept () {
        const { target: commentId = 0 } = this.audit
        this.$http
          .patch(`/plus-group/currency-pinned/comments/${commentId}/accept`, {
            validateStatus: s => s === 201,
          })
          .then(({ data }) => {
            this.audit.expires_at = 1
            this.audit.status = 1
            this.$Message.success(data)
          })
      },
      reject () {
        const { target: commentId = 0 } = this.audit
        this.$http
          .patch(`/plus-group/currency-pinned/comments/${commentId}/reject`, {
            validateStatus: s => s === 201,
          })
          .then(({ data }) => {
            this.audit.expires_at = 1
            this.audit.status = 2
            this.$Message.success(data)
          })
      },
    },
  }
</script>
